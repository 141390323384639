module.exports = [{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"oreos2011","partialMatching":true,"pagePaths":["/silent-treatment"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Sapp","short_name":"avidsapp","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"61916abe99203c487694809bbd4bda19"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
